// src/pages/Contact.js
import React from "react";

const Contact = () => {
  return (
    <div>
      <h2>Contact Page</h2>
    </div>
  );
};

export default Contact;
